import React from "react"
import { graphql } from "gatsby"
import PostListPage from "../components/PostListPage"

export const query = graphql`
  query {
    posts: allContentfulPost(
      sort: { fields: [createdAtOriginal, createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          ...PostCellFragment
        }
      }
    }
    categoryList: allContentfulPost {
      group(field: category) {
        fieldValue
        edges {
          node {
            slug
          }
        }
      }
    }
    tagList: allContentfulPost {
      distinct(field: tags)
    }
  }
`
const Page = (props) => <PostListPage {...props} />

export default Page
